import { ToastConfig } from '../models/toast.models'
import { ConnectedPosition } from '@angular/cdk/overlay'

export const DEFAULT_TOAST_CONFIG: ToastConfig = {
  position: 'top-right',
  margin: '20px',
  animation: {
    fadeOut: 2500,
    fadeIn: 300
  },
  duration: 5000,
  size: 'medium'
}

export const BOTTOM_TOAST_CONNECTED_POSITIONS: ConnectedPosition[] = [
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top'
  }
]
