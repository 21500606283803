import { createAction, props } from '@ngrx/store';
import { TextDirection } from './translation.reducer';

export const changeLanguage = createAction(
  '[Translation] Change Language',
  props<{ language: string }>()
);
export const changeTextDirection = createAction(
  '[Translation] Change Text Direction',
  props<{ textDirection: TextDirection }>()
);
