import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EventSummaryRequestState } from '@spa-state';
import { Observable } from 'rxjs';
import { DTPlayerPayload, DTTeamPayload } from 'src/app/core/types/data-table.types';
import { EFMConfig } from 'src/app/core/types/event.types';
@Injectable({ providedIn: 'root' })
export class EventsService {
  constructor(private http: HttpClient) { }

  getCategories(): Observable<any[]> {
    return this.http.get<any>(`${environment.API_URL}/EventType?page=1&pageSize=100000`)
  }

  getEventsBasedOnSelection(requestConfig: Partial<EventSummaryRequestState>): Observable<any> {
    return this.http
      .post<any>(`${environment.API_URL}/EventType?page=1&pageSize=100000`, {
        ...requestConfig,
      })
  }

  getEventsBasedOnStartArea(requestConfig: EFMConfig): Observable<any> {
    return this.http
      .post<any>(`${environment.API_URL}/Event/filtered-events?page=1&pageSize=100000`, {
        ...requestConfig,
      })
  }

  getMatchMetricsByTeams(payload: DTTeamPayload): Observable<any> {
    return this.http
      .post<any>(`${environment.API_URL}/MatchMetrics`, payload);
  }

  getMatchMetricsByPlayers(payload: DTPlayerPayload): Observable<any> {
    return this.http
      .post<any>(`${environment.API_URL}/MatchMetrics/GetPlayerMatchesMetrics`, payload);
  }
}
