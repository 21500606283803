import { ToastConfig } from '../models/toast.models'
import { Overlay, PositionStrategy } from '@angular/cdk/overlay'
import { ToastRef } from '../models/toast-ref.model'
import { BOTTOM_TOAST_CONNECTED_POSITIONS } from '../constants/toast.constants'

const getPosition = (lastToast: ToastRef, postion: 'top' | 'bottom'): string | undefined => {
  return lastToast?.isVisible() ? lastToast.getPosition()[postion] + 10 + 'px' : undefined
}

export const buildPositionStrategy = (overlay: Overlay, config: ToastConfig, lastToast: ToastRef): PositionStrategy => {
  switch (config.position) {
    case 'top':
      return overlay
        .position()
        .global()
        .centerHorizontally()
        .top(getPosition(lastToast, 'bottom') || config.margin)

    case 'bottom':
      return lastToast?.isVisible()
        ? overlay.position().flexibleConnectedTo(lastToast.getElement()).withPositions(BOTTOM_TOAST_CONNECTED_POSITIONS)
        : overlay
            .position()
            .global()
            .centerHorizontally()
            .bottom(getPosition(lastToast, 'top') || config.margin)

    case 'left':
      return lastToast?.isVisible()
        ? overlay.position().global().top(getPosition(lastToast, 'bottom')).left(config.margin)
        : overlay.position().global().centerVertically().left(config.margin)

    case 'right':
      return lastToast?.isVisible()
        ? overlay.position().global().top(getPosition(lastToast, 'bottom')).right(config.margin)
        : overlay.position().global().centerVertically().right(config.margin)

    case 'top-left':
      return overlay
        .position()
        .global()
        .top(getPosition(lastToast, 'bottom') || config.margin)
        .left(config.margin)

    case 'bottom-right':
      return lastToast?.isVisible()
        ? overlay.position().flexibleConnectedTo(lastToast.getElement()).withPositions(BOTTOM_TOAST_CONNECTED_POSITIONS)
        : overlay
            .position()
            .global()
            .bottom(getPosition(lastToast, 'top') || config.margin)
            .right(config.margin)

    case 'bottom-left':
      return lastToast?.isVisible()
        ? overlay.position().flexibleConnectedTo(lastToast.getElement()).withPositions(BOTTOM_TOAST_CONNECTED_POSITIONS)
        : overlay
            .position()
            .global()
            .bottom(getPosition(lastToast, 'top') || config.margin)
            .left(config.margin)

    default:
      return overlay
        .position()
        .global()
        .top(getPosition(lastToast, 'bottom') || config.margin)
        .right(config.margin)
  }
}
