import { Injectable, Injector } from '@angular/core'
import { ToastRef } from '../models/toast-ref.model'
import { Overlay } from '@angular/cdk/overlay'
import { ToastConfig, ToastData } from '../models/toast.models'
import { ComponentPortal } from '@angular/cdk/portal'
import { buildPositionStrategy } from '../utils/toast.utils'
import { ToastComponent } from '../components/toast/toast.component'
import { TOAST_CONFIG_TOKEN, TOAST_DATA_TOKEN, TOAST_REF_TOKEN } from '../tokens/toast.tokens'

@Injectable()
export class ToastService {
  #lastToast!: ToastRef
  constructor(private overlay: Overlay) {}

  show(data: ToastData, config: ToastConfig) {
    const overlayRef = this._buildOverlayRef(config)
    this.#lastToast = new ToastRef(overlayRef)
    const injector = this._buildInjector(data, config)

    const portal = new ComponentPortal(ToastComponent, null, injector)
    overlayRef.attach(portal)
  }

  private _buildOverlayRef(config: ToastConfig) {
    return this.overlay.create({
      positionStrategy: buildPositionStrategy(this.overlay, config, this.#lastToast),
      hasBackdrop: false
    })
  }

  private _buildInjector(data: ToastData, config: ToastConfig) {
    return Injector.create({
      providers: [
        { provide: TOAST_CONFIG_TOKEN, useValue: config },
        { provide: TOAST_DATA_TOKEN, useValue: data },
        { provide: TOAST_REF_TOKEN, useValue: this.#lastToast }
      ]
    })
  }
}
