import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core'
import { ToastType } from '../../models/toast.models'

@Component({
  selector: 'spaui-toast-icon',
  templateUrl: './toast-icon.component.html',
  styleUrls: ['./toast-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ToastIconComponent {
  @Input() severity!: ToastType
}
