import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core'
import { ToastAnimationState, ToastConfig, ToastData, ToastSize } from '../../models/toast.models'
import { AnimationEvent } from '@angular/animations'

import { ToastRef } from '../../models/toast-ref.model'
import { DEFAULT_TOAST_CONFIG } from '../../constants/toast.constants'
import { TOAST_CONFIG_TOKEN, TOAST_DATA_TOKEN, TOAST_REF_TOKEN } from '../../tokens/toast.tokens'
import { toastAnimations } from '../../animations/fade.animation'

@Component({
  selector: 'spaui-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.fadeToast],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ToastComponent implements OnInit, OnDestroy {
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef)
  data: ToastData = inject(TOAST_DATA_TOKEN) as ToastData
  ref: ToastRef = inject(TOAST_REF_TOKEN) as ToastRef
  config: ToastConfig = inject(TOAST_CONFIG_TOKEN) as ToastConfig

  #timeoutId!: ReturnType<typeof setTimeout>
  animationState: ToastAnimationState = 'default'

  get fadeIn(): number {
    return this.config.animation?.fadeIn ?? DEFAULT_TOAST_CONFIG.animation?.fadeIn!
  }
  get fadeOut(): number {
    return this.config.animation?.fadeOut ?? DEFAULT_TOAST_CONFIG.animation?.fadeOut!
  }

  get duration(): number {
    return this.config?.duration ?? DEFAULT_TOAST_CONFIG.duration!
  }

  get size(): ToastSize {
    return this.config?.size ?? DEFAULT_TOAST_CONFIG.size!
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event
    const isFadeOut = (toState as ToastAnimationState) === 'closing'
    const itFinished = this.animationState === 'closing'

    if (isFadeOut && itFinished) {
      this.close()
    }
  }

  close() {
    this.ref?.close()
  }

  ngOnInit(): void {
    this.#timeoutId = setTimeout(() => {
      this.animationState = 'closing'
      this.cdr.detectChanges()
    }, this.duration)
  }
  ngOnDestroy(): void {
    clearTimeout(this.#timeoutId)
  }
}
