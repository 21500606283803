import { createReducer, on } from '@ngrx/store';
import { changeLanguage, changeTextDirection } from './translation.actions';

export type TextDirection = 'ltr' | 'rtl';

export interface TranslationState {
  language: string;
  textDirection: TextDirection;
}

export const initialTranslationState: TranslationState = {
  language: 'en',
  textDirection: 'ltr',
};

export const translationReducer = createReducer(
  initialTranslationState,
  on(changeLanguage, (state, {language}) => ({ ...state, language })),
  on(changeTextDirection, (state, {textDirection}) => ({ ...state, textDirection }))
);