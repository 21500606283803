@switch (severity) {
  @case ('success') {
    <img src="assets/images/icons/toast-success.svg" alt="">
  }
  @case ('info') {
    <img src="assets/images/icons/toast-info.svg" alt="">
  }
  @case ('warning') {
    <img src="assets/images/icons/toast-warning.svg" alt="">
  }
  @case ('error') {
    <img src="assets/images/icons/toast-error.svg" alt="">
  }
  @case ('tip') {
    <img src="assets/images/icons/toast-tip.svg" alt="">
  }
  @default {
    <img src="assets/images/icons/toast-success.svg" alt="">
  }
}