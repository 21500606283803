import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { tap } from "rxjs";
import { changeLanguage, changeTextDirection } from "./translation.actions";
import { TextDirection } from "./translation.reducer";
import { Store } from "@ngrx/store";

@Injectable()
export class TranslationEffects {
  changeLanguageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLanguage),
      tap(({language}) => {
        const textDirection: TextDirection = language === 'ar' ? 'rtl' : 'ltr';
        this.store.dispatch(changeTextDirection({textDirection}));
      })
    ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store) {}
}