<div
  [ngClass]="[config.type, size].join(' ')"
  class="spaui-toast"
  [@fadeAnimation]="{value: animationState, params:
        { fadeIn: fadeIn, fadeOut: fadeOut }}"
  (@fadeAnimation.done)="onFadeFinished($event)">
  <spaui-toast-icon [severity]="config.type || 'success'"></spaui-toast-icon>
  <div class="spaui-toast__content">
    <div class="spaui-toast__title" *ngIf="data.title">{{ data.title }}</div>
    <div class="spaui-toast__message" *ngIf="data.message">{{ data.message }}</div>
    <div class="spaui-toast__action-links" *ngIf="data.actionLinks">
      <div *ngFor="let link of data.actionLinks">
        <a [href]="link.url">{{ link.label }}
        </a>
        <!-- TODO: Replace image with icon -->
        <img src="assets/images/icons/open-in-new.svg" alt="open-in-new">
      </div>
    </div>
    
  </div>
  <!-- TODO: Replace image with icon -->
  <img src="assets/images/icons/close.svg" (click)="close()" alt="close">
</div>
