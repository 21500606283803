import { ZoneCellBorder } from '../core/types/zone.types';

export const ALL_BORDERS_ENABLED: ZoneCellBorder = {
  top: true,
  right: true,
  bottom: true,
  left: true,
};

export const NO_BORDERS_ENABLED: ZoneCellBorder = {
  top: false,
  right: false,
  bottom: false,
  left: false,
};

export const ZONE_COLORS = {
  empty: 'transparent',
  selection: '#BED2F2',
  default: '#DFF099',
  hover: '#DFF099',
  selected: '#B0DA00',
  cellSelected: '#bee133',
  zoneSelected: '#344b5b'
};

export const ZONE_BORDER_STYLES = {
  empty: 'dashed',
  selection: 'none',
  default: 'solid',
  hover: 'dashed',
  selected: 'none',
};
