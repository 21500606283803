import { createSelector, createFeatureSelector } from '@ngrx/store';
import { TranslationState } from './translation.reducer';

export const selectTranslationState = createFeatureSelector<TranslationState>('translation');

export const selectLanguage = createSelector(
  selectTranslationState,
  (state: TranslationState) => state?.language
);

export const selectTextDirection = createSelector(
  selectTranslationState,
  (state: TranslationState) => state?.textDirection
);