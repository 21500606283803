import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  addDashboard,
  addDashboardSuccess,
  setDashboardsLoading,
  deleteDashboard,
  deleteDashboardSuccess,
  getAllCompetitions,
  getAllDashboard,
  getAllDashboardTypes,
  getAllMatches,
  getAllPlayers,
  getAllTeams,
  getDashboardDetail,
  getEvents,
  getMoreDashboard,
  getSortedDashboard,
  handleError,
  loadCompetitionsSuccess,
  loadDashboardDetailSuccess,
  loadDashboardFailure,
  loadDashboardSuccess,
  loadDashboardTypes,
  loadEventsSuccess,
  loadMatchesSuccess,
  loadMoreDashboardSuccess,
  loadPlayersSuccess,
  loadTeamsSuccess,
  updateDashboard,
  updateDashboardStructure,
  updateDashboardStructureSuccess,
  updateDashboardSuccess,
  setCompetitionLoading,
  setTeamLoading,
  setPlayerLoading,
  setMatchLoading,
  loadMetricsSuccess,
  getMetrics,
  loadTeamMetricsSuccess,
  loadPlayerMetricsSuccess,
} from './dashboard.actions';
import { of } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Store } from '@ngrx/store';
import { AppState, selectDashboard } from '@spa-state';
import { DASHBOARD_API_PARAM } from 'src/app/constants';
import { EventsService } from 'src/app/services/events/events.service';
@Injectable()
export class DashboardEffects {
  getAllDashboardEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllDashboard),
      withLatestFrom(this.store.select(selectDashboard)),
      mergeMap(([action, state]) => {
        if (action.reload || state.value.items.length < 1) {
          this.store.dispatch(setDashboardsLoading({ loading: true }));
          return this.dashboardService
            .getAllDashboard(DASHBOARD_API_PARAM)
            .pipe(
              map((data) => loadDashboardSuccess({ data })),
              catchError((error) => of(loadDashboardFailure({ error })))
            );
        } else {
          return of(loadDashboardSuccess({ data: state }));
        }
      })
    )
  );

  getMoreDashboardEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMoreDashboard),
      mergeMap((action) =>
        this.dashboardService.getAllDashboard(action.apiParams).pipe(
          map((data) => loadMoreDashboardSuccess({ data })),
          catchError((error) => of(loadDashboardFailure({ error })))
        )
      )
    )
  );

  getSortedDashboardEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSortedDashboard),
      mergeMap((action) =>
        this.dashboardService.getAllDashboard(action.apiParams).pipe(
          map((data) => loadDashboardSuccess({ data })),
          catchError((error) => of(loadDashboardFailure({ error })))
        )
      )
    )
  );

  getAllDashboardTypesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllDashboardTypes),
      mergeMap(() =>
        this.dashboardService
          .getAllDashboardTypes()
          .pipe(map((data) => loadDashboardTypes({ data })))
      )
    )
  );

  addDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addDashboard),
      switchMap(({ data }) =>
        this.dashboardService.addDashboard(data).pipe(
          map((response) => addDashboardSuccess({ data: response })),
          catchError((errors) => of(handleError({ errors })))
        )
      )
    )
  );

  deleteDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDashboard),
      switchMap(({ dashboardId }) =>
        this.dashboardService.deleteDashboard(dashboardId).pipe(
          map(() => deleteDashboardSuccess()),
          catchError((errors) => of(handleError({ errors })))
        )
      )
    )
  );

  updateDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDashboard),
      switchMap(({ data }) =>
        this.dashboardService.updateDashboard(data).pipe(
          map(() => updateDashboardSuccess({ data: data.dashboardId! })),
          catchError((errors) => of(handleError({ errors })))
        )
      )
    )
  );

  updateDashboardStructure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDashboardStructure),
      switchMap(({ data }) =>
        this.dashboardService.updateDashboardStructure(data).pipe(
          map(() =>
            updateDashboardStructureSuccess({ data: data.dashboardId! })
          ),
          catchError((errors) => of(handleError({ errors })))
        )
      )
    )
  );

  getDashboardDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDashboardDetail),
      switchMap(({ dashboardId }) => {
        this.store.dispatch(setDashboardsLoading({ loading: true }));
        return this.dashboardService.getDashboardDetail(dashboardId).pipe(
          map((data) => loadDashboardDetailSuccess({ data })),
          catchError((errors) => of(handleError({ errors })))
        );
      })
    )
  );

  getAllCompetitionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllCompetitions),
      mergeMap(() => {
        this.store.dispatch(setCompetitionLoading({ loading: true }))
        return this.dashboardService.getAllCompetitions().pipe(
          map((data) => loadCompetitionsSuccess({ data })),
          catchError((errors) => of(handleError({ errors })))
        )
      })
    )
  );

  getAllTeamsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllTeams),
      mergeMap((action) => {
        this.store.dispatch(setTeamLoading({ loading: true }))
        return this.dashboardService.getAllTeams(action.payload).pipe(
          map((data) => loadTeamsSuccess({ data })),
          catchError((errors) => of(handleError({ errors })))
        )
      })
    )
  );

  getAllPlayersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllPlayers),
      mergeMap((action) => {
        this.store.dispatch(setPlayerLoading({ loading: true }))
        return this.dashboardService.getAllPlayers(action.payload).pipe(
          map((data) => loadPlayersSuccess({ data })),
          catchError((errors) => of(handleError({ errors })))
        )
      })
    )
  );

  getAllMatchesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllMatches),
      mergeMap((action) => {
        this.store.dispatch(setMatchLoading({ loading: true }))
        return this.dashboardService.getAllMatches(action.payload).pipe(
          map((data) => loadMatchesSuccess({ data })),
          catchError((errors) => of(handleError({ errors })))
        )
      })
    )
  );

  getAllEventsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEvents),
      mergeMap((action) =>
        this.eventService.getCategories().pipe(
          map((data: any) => loadEventsSuccess({ data: data.items })),
          catchError((errors) => of(handleError({ errors })))
        )
      )
    )
  );

  getMetricsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMetrics),
      mergeMap(({ metricType }) =>this.dashboardService.getMetrics(metricType).pipe(
        map((data) => {
          switch (metricType) {
            case 'team':
              return loadTeamMetricsSuccess({ data });
            case 'player':
              return loadPlayerMetricsSuccess({ data });
            default:
              return loadMetricsSuccess({ data });
          }
        }),
        catchError((errors) => of(handleError({ errors })))
      )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private store: Store<AppState>,
    private eventService: EventsService
  ) { }
}
