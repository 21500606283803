import { FilteredEvents } from "./event.types";

export enum SFMMetrics {
  number = 'number',
  average = 'average',
  percentage = 'percentage',
}

export enum SFMPitchArea {
  startArea = 'startArea',
  endArea = 'endArea',
}

export interface SFMZoneConfig {
  rows: number;
  columns: number;
}

export interface SMFCell {
  row: number;
  column: number;
}

export interface SFMDisplayOptions {
  basedOn: SFMPitchArea;
  metrics: SFMMetrics[];
}

export interface SFMSelectedZone {
  zone: number;
  cells: SMFCell[];
}

export interface SMFPayload {
  selectedTeams: number[];
  selectedPlayers: number[];
  selectedMatches: number[];
  selectedEvents: number[];
  zoneConfiguration: SFMZoneConfig;
  displayOptions: SFMDisplayOptions;
  selectedZones: SFMSelectedZone[];
}

export interface SFMConfig {
  payload: SMFPayload;
  events: number[];
  showOpponent: boolean;
}

export interface SMFSummary {
  id: number;
  events?: FilteredEvents[];
  properties: {
    [SFMMetrics.number]?: number;
    [SFMMetrics.average]?: number;
    [SFMMetrics.percentage]?: number;
  };
}

export interface SMFResponseZone {
  cells: SMFCell[];
  zone: number;
  summary: SMFSummary;
}

export interface SMFResponse {
  zones: SMFResponseZone[];
}

export interface SMFSelectorEvent {
  teams: number[];
  players: number[];
  events: number[];
  metrics?: number[];
  matches?: number[]
}

export interface SiblingCells {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
