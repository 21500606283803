import { createAction, props } from '@ngrx/store';
import { Competition, Match, Player, Team } from 'src/app/core/types/dashboard-selector.types';
import { DashboardStructurePayload } from 'src/app/core/types/dashboard-structure.types';
import { APIParams, APIResponse, DashboardNewElement } from 'src/app/core/types/spa.types';




//Get Dashboards
export const getAllDashboard = createAction('[Dashboard] Get All Dashboard', props<{ reload: boolean }>());
export const refreshDashboard = createAction('[Dashboard] Refresh Dashboard', props<{ data: any }>());
export const loadDashboardSuccess = createAction('[Dashboard] Load Dashboard Success', props<{ data: any }>());
export const getMoreDashboard = createAction('[Dashboard] Get More Dashboard', props<{ apiParams: APIParams }>());
export const loadMoreDashboardSuccess = createAction('[Dashboard] Load More Dashboard Success', props<{ data: any }>());
export const getSortedDashboard = createAction('[Dashboard] Get Sorted Dashboard', props<{ apiParams: APIParams }>());
export const loadDashboardFailure = createAction('[Dashboard] Load Dashboard Failure', props<{ error: any }>());
export const setDashboardsLoading = createAction('[Dashboard] Dashboard list loading', props<{ loading: boolean }>());

//Get Dashboard Detail
export const getDashboardDetail = createAction('[Dashboard] Get Dashboard Detail', props<{ dashboardId: number }>())
export const loadDashboardDetailSuccess = createAction('[Dashboard] Get Dashboard Detail Success', props<{ data: any }>())

// Add Dashboard
export const addDashboard = createAction('[Dashboard] Add Dashboard', props<{ data: DashboardNewElement }>());
export const addDashboardSuccess = createAction('[Dashboard] Add Dashboard Success', props<{ data: number }>());
export const addDashboardFailure = createAction('[Dashboard] Add Dashboard Failure', props<{ error: any }>());

// Delete Dashboard
export const deleteDashboard = createAction('[Dashboard] Delete Dashboard', props<{ dashboardId: number }>());
export const deleteDashboardSuccess = createAction('[Dashboard] Delete Dashboard Success');

// Update Dashboard
export const updateDashboard = createAction('[Dashboard] Update Dashboard', props<{ data: DashboardNewElement }>());
export const updateDashboardSuccess = createAction('[Dashboard] Update Dashboard Success', props<{ data: number }>());

// Update Dashboard Structure
export const updateDashboardStructure = createAction('[Dashboard] Update Dashboard Structure', props<{ data: DashboardStructurePayload }>());
export const updateDashboardStructureSuccess = createAction('[Dashboard] Update Dashboard Structure Success', props<{ data: number }>());

// Get Dashboard Types
export const getAllDashboardTypes = createAction('[Dashboard] Get All Dashboard Types');
export const loadDashboardTypes = createAction('[Dashboard] Load Dashboard Type Success', props<{ data: any }>());

// Get Competitions
export const getAllCompetitions = createAction('[Competitions] Get All Competitions');
export const loadCompetitionsSuccess = createAction('[Competitions] Load Competitions Success', props<{ data: APIResponse<Competition> }>());
export const setCompetitionLoading = createAction('[Competitions] Set Competitions loading', props<{ loading: boolean }>());

// Get Teams
export const getAllTeams = createAction('[Teams] Get All Teams', props<{ payload: any }>());
export const loadTeamsSuccess = createAction('[Teams] Load Teams Success', props<{ data: APIResponse<Team> }>());
export const setTeamLoading = createAction('[Teams] Set Team loading', props<{ loading: boolean }>());

// Get Players
export const getAllPlayers = createAction('[Players] Get All Players', props<{ payload: any }>());
export const loadPlayersSuccess = createAction('[Players] Load Players Success', props<{ data: APIResponse<Player> }>());
export const setPlayerLoading = createAction('[Players] Set Players loading', props<{ loading: boolean }>());

// Get Matches
export const getAllMatches = createAction('[Matches] Get All Matches', props<{ payload: any }>());
export const loadMatchesSuccess = createAction('[Matches] Load Matches Success', props<{ data: APIResponse<Match> }>());
export const setMatchLoading = createAction('[Matches] Set Matches loading', props<{ loading: boolean }>());

// Get Events
export const getEvents = createAction('[Events] Get Event List');
export const loadEventsSuccess = createAction('[Events] Load Events Success', props<{ data: any }>());

export const updateTeams = createAction('[Teams] Update Teams', props<{ data: number[] }>());
export const updatePlayers = createAction('[Players] Update Players', props<{ data: number[] }>());
export const updateMatches = createAction('[Matches] Update Matches', props<{ data: number[] }>());
export const updateEvents = createAction('[Events] Update Events', props<{ data: number[] }>());
export const updateCompetitions = createAction('[Events] Update Competitions', props<{ data: any[] }>());

// Get selected values of dataset selectors
export const updateSelectorCompetitions = createAction('[Selector] Update Competitions', props<{ data: Competition[] }>());
export const updateSelectorTeams = createAction('[Selector] Update Teams', props<{ data: Team[] }>());
export const updateSelectorPlayers = createAction('[Selector] Update Players', props<{ data: Player[] }>());
export const updateSelectorMatches = createAction('[Selector] Update Matches', props<{ data: Match[] }>());
export const updateSelectorFilteredPlayers = createAction('[Selector] Update Filtered Players', props<{ data: Player[] }>());

// Get Events
export const getMetrics = createAction(
    '[Metrics] Get Metric List',
    props<{ metricType: 'all' | 'team' | 'player' }>()
  );
export const loadMetricsSuccess = createAction('[Metrics] Load Metrics Success', props<{ data: any }>());
export const loadTeamMetricsSuccess = createAction('[Metriccs] Load Team Metrics Success', props<{ data: any[] }>());
export const loadPlayerMetricsSuccess = createAction('[Metrics] Load Player Metrics Success', props<{ data: any[] }>());

export const handleError = createAction('Handle Error', props<{ errors: any[] }>());