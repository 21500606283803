import { WidgetStructureType } from "../core/types/dashboard-structure.types";

export const WIDGET_STRUCTURE: WidgetStructureType = {
    "8x4": {
        resizable: true,
        draggable: true,
        x: 0,
        y: 0,
        cols: 4,
        rows: 8,
        minItemCols: 4,
        minItemRows: 8,
        maxItemRows: 24,
    },
    "12x12": {
        resizable: true,
        draggable: true,
        x: 0,
        y: 0,
        cols: 12,
        rows: 12,
        minItemCols: 8,
        minItemRows: 8,
        maxItemRows: 12,
    }
}