import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import { User } from 'oidc-client';
import oidcClient from 'oidc-client';
import { Subject } from 'rxjs';

const { UserManager } = oidcClient;
const helper = new JwtHelperService();
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly TOKEN_NAME = 'token';
  private userManager: any;
  private user: any = null;
  private logoutSubject = new Subject<void>();
  logout$ = this.logoutSubject.asObservable();
  config = {}
  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) {
    this.config = environment.idsConfig
    this.userManager = new UserManager(this.config);
  }

  get token(): string {
    return localStorage.getItem(this.TOKEN_NAME) || '';
  }

  getConfig(): any {
    return this.config;
  }

  get isTokenExpired() {
    return helper.isTokenExpired(this.token);
  }

  setToken(token: string): void {
    localStorage.setItem(this.TOKEN_NAME, token);
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }
  completeAuthentication() {
    return this.userManager.signinRedirectCallback().then((user: any) => {
      this.user = user;
      this.router.navigate(['/dashboards']);
    });
  }
  // TODO: Use for logout
  // logout(): void {
  //   localStorage.removeItem(this.TOKEN_NAME);
  // }

  login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  getUser(): Promise<any> {
    return this.userManager.getUser();
  }

  showLogoutMessage(showMessage: boolean) {
    const dashboardUrlPattern = /^\/?dashboards\/[^\/]+$/;
    if (showMessage && this.router?.url?.includes("dashboards/")) {
      const language = localStorage.getItem('language');
      const message = language === "es"
        ? "¿Quieres salir del sitio web? \nEs posible que los cambios no se guarden."
        : "Do you want to leave the website? \nYour changes may not be saved.";

      const userConfirmed = confirm(message);

      if (userConfirmed) {
        this.logout();
      }
    }
  }

  triggerLogout() {
    this.logoutSubject.next();
  }
}
