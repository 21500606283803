import { WidgetsConfig } from "../core/types/dashboard-structure.types";
import { SFMPitchArea, SFMMetrics } from "../core/types/summary-fieldmap.types";

export const WIDGETS_CONFIG: WidgetsConfig = {
  VA_CONFIG: {
    events: [],
    zoneConfiguration: {
      Columns: 6,
      Rows: 5,
    },
    startArea: [],
    endArea: [],
    showOpponent: false,
  },
  SFM_CONFIG: {
    payload: {
      selectedTeams: [],
      selectedPlayers: [],
      selectedMatches: [],
      selectedEvents: [],
      zoneConfiguration: {
        rows: 5,
        columns: 6,
      },
      displayOptions: {
        basedOn: SFMPitchArea.startArea,
        metrics: [SFMMetrics.number],
      },
      selectedZones: [],
    },
    events: [],
    showOpponent: false,
  },
  EFM_CONFIG: {
    events: [],
    teams: [],
    players: [],
    matches: [],
    selectedEvents: [],
    zoneConfiguration: {
      Columns: 6,
      Rows: 5,
    },
    startArea: [],
    endArea: [],
    showOpponent: false,
  },
  DT_TEAM_CONFIG: {
    payload: {
      matchesIds: [],
      teamsIds: [],
      metricTypeIds: [],
    },
    displayOptions: {
      matchPerimeter: 'datasetSelection',
      rowOptions: {
        individualGames: true,
        average: false,
        aggregate: false,
      }
    }
  },
  DT_PLAYER_CONFIG: {
    matchesIds: [],
    playersIds: [],
    teamsIds: [],
    metricTypeIds: [],
    displayOptions: {
      matchPerimeter: 'datasetSelection',
      rowOptions: {
        individualGames: true,
        average: false,
        aggregate: false,
      }
    }
  },
};
